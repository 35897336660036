<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title>Disclaimer</b-card-title>
                <b-card-text>
                    <h2>Disclaimer</h2>
                    <p>Ridder en de Prins Entertainment (Kamer van Koophandel: KvK), hierna te noemen RedP, verleent u hierbij toegang tot www.ridderendeprins.nl (“de Website”) en publiceert hier ter informatie teksten, afbeeldingen en andere materialen.</p>
                    <p>RedP behoudt zich daarbij het recht voor op elk moment de inhoud aan te passen of onderdelen te verwijderen zonder daarover aan u mededeling te hoeven doen.</p>
                    <p>De informatie op de Website is bedoeld als vrijblijvend en niet als een concreet aanbod om een overeenkomst te sluiten. Overeenkomsten worden alleen gesloten door aanvaarding van een als zodanig aangeduide offerte van de Website.</p>
                    <p><strong> Beperkte aansprakelijkheid</strong></p>
                    <p>RedP spant zich in om de inhoud van de Website zo vaak mogelijk te actualiseren en/of aan te vullen. Ondanks deze zorg en aandacht is het mogelijk dat inhoud onvolledig en/of onjuist is.</p>
                    <p>De op de Website aangeboden diensten worden aangeboden zonder enige vorm van garantie of aanspraak op juistheid. Deze diensten kunnen op elk moment wijzigen zonder voorafgaande mededeling van RedP.</p>
                    <p>In het bijzonder zijn alle prijzen op de Website onder voorbehoud van type- en programmeerfouten. Voor de gevolgen van dergelijke fouten wordt geen aansprakelijkheid aanvaard. Geen overeenkomst komt tot stand op basis van dergelijke fouten.</p>
                    <p><strong>Auteursrechten</strong></p>
                    <p>Alle rechten van intellectuele eigendom betreffende deze materialen liggen bij RedP. Kopiëren, verspreiden en elk ander gebruik van deze materialen is niet toegestaan zonder schriftelijke toestemming van RedP, behoudens en slechts voor
                        zover anders bepaald in regelingen van dwingend recht (zoals citaatrecht), tenzij bij specifieke materialen anders aangegeven is.</p>
                    <p><strong>Overig</strong></p>
                    <p>Deze disclaimer kan van tijd tot tijd wijzigen.</p>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Disclaimer'
    }
</script>

<style>

</style>
	